<template>
  <div class="login-wrap">
    <div class="header-wrap"></div>
    <div class="login-item-wrap">
      <div class="form-wrap" v-if="current === 0">
        <div class="form-item">
          <MyField v-model="state.mobile" maxlength="11" label="手机号码" placeholder="请输入手机号码" />
        </div>
        <div class="form-item">
          <MyField v-model="state.captcha" maxlength="6" label="验证码" placeholder="请输入验证码">
            <div>
              <div class="vertifiy-wrap" v-if="!isCountDown" @click="vertifyHandle">
                <span class="line">|</span>
                <span>获取验证码</span>
              </div>
              <div class="my-countdown" v-else>
                <span class="line">|</span>
                <CountDown @finish="finishHandle" :time="time" format ="ss"/>
                <div class="secends">s</div>
              </div>
            </div>
          </MyField>
        </div>
        <div class="login-btn" @click="nextStep">下一步</div>
      </div>
      <div class="form-wrap" v-else>
        <div class="form-item">
          <MyField v-model="state.mm" type="password" label="新密码" placeholder="请输入新密码" />
        </div>
        <div class="form-item">
          <MyField v-model="state.confirm_password" type="password" label="确认新密码" placeholder="请再次输入新密码" />
        </div>
        <div class="login-btn" @click="confirmHandle">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { Toast, CountDown } from 'vant'
import MyField from '@/components/my-input/'
import { getCaptcha, forgetApi } from '@/api/'
import { useRouter } from 'vue-router'
// import { deleteObjEmptyData } from '@/utils/'

export default {
  name: 'passwordReset',
  components: {
    MyField,
    CountDown
  },
  setup () {
    const current = ref(0)
    const state = reactive({
      mm: '',
      confirm_password: '',
      mobile: '',
      captcha: ''
    })
    const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    const checked = ref(false)
    const isCountDown = ref(false)
    const time = ref(60 * 1000)
    const router = useRouter()

    const clickHnadle = (index) => {
      current.value = index
    }

    const finishHandle = () => {
      isCountDown.value = false
    }

    const nextStep = () => {
      if (!reg.test(state.mobile)) {
        Toast('请输入正确的手机号码')
      } else if (state.captcha.length !== 6) {
        Toast('请输入正确的验证码')
      } else {
        current.value = 1
      }
    }

    const vertifyHandle = () => {
      if (reg.test(state.mobile)) {
        isCountDown.value = true
        getCaptcha({
          mobile: state.mobile
        }).then(res => {
          console.log(res)
        })
      } else {
        Toast('请输入正确的手机号码')
      }
    }

    const confirmHandle = () => {
      if (state.mm === '' || state.confirm_password === '') {
        Toast('新密码不能为空')
      } else if (state.mm !== state.confirm_password) {
        Toast('两次密码输入不一致')
      } else {
        // 确认密码
        forgetApi(state).then(res => {
          console.log(res)
          if (res.code === 200) {
            Toast('密码重置成功')
            router.replace('/login')
          } else {
            Toast(res.message)
          }
        })
      }
    }

    return {
      nextStep,
      state,
      time,
      isCountDown,
      clickHnadle,
      checked,
      confirmHandle,
      vertifyHandle,
      finishHandle,
      current
    }
  }
}
</script>

<style lang="less" scoped>
.login-wrap {
  width: 100%;
  height: 100%;
  background: #fff;
}
.header-wrap {
  width: 100%;
  height: 162px;
  background: url('../../assets/login-bg.png') no-repeat;
  background-size: cover;
}
.login-item-wrap {
  margin-top: 56px;
  .tab-wrap {
    display: flex;
    padding: 12px 36px 0 36px;
    .tab-item {
      position: relative;
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      &:nth-of-type(1) {
        margin-right: 24px;
      }
    }
    .tab-item-active {
      color: #0080FF;
      &::after {
        content: '';
        position: absolute;
        box-sizing: border-box;
        width: 22px;
        height: 2px;
        background: #0080FF;
        border-radius: 1px;
        pointer-events: none;
        left: 0;
        right: 0;
        bottom: -2px;
        margin:auto;
      }
    }
  }
  .form-wrap {
    padding: 0 36px;
    .form-item {
      margin-top: 36px;
    }
  }
  .auto-login {
    display: flex;
    margin-top: 16px;
    padding-left: 36px;
    .text {
      margin-left: 9px;
      font-size: 14px;
      color: #494E53;
    }
  }
}
.login-btn {
  width: 100%;
  margin: 52px 36px 0 0;
  height: 44px;
  background: #0C86FE;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: #FFFFFF;
}
.vertifiy-wrap {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #007FFF;
  white-space: nowrap;
  .line {
    color: #B9C6D3;
    margin-right: 8px;
  }
}
.my-countdown {
  width: 81px;
  display: flex;
  justify-content: center;
  .line {
    color: #B9C6D3;
    margin-right: 8px;
    font-size: 14px;
  }
  .secends {
    color: #B9C6D3;
    margin-left: 4px;
    font-size: 14px;
  }
}
</style>
